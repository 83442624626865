export default abstract class Logger {
  protected abstract formatOperation(module: string, op: string, params: Record<string, any>, duration: number): any;

  message(type: string, name: string, params: Record<string, any>, duration: number = 0) {
    // eslint-disable-next-line no-console
    console.log(this.formatOperation(type, name, params, duration));
  }

  warn(name: string, params: Record<string, any>) {
    // eslint-disable-next-line no-console
    console.log(this.formatOperation('warn', name, params, 0));
  }

  error(name: string, params: Record<string, any>) {
    // eslint-disable-next-line no-console
    console.log(this.formatOperation('error', name, params, 0));
  }
}
