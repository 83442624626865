import {
  ApolloClient,
  ApolloLink,
  ApolloQueryResult,
  DocumentNode,
  FetchPolicy,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { PHASE_PRODUCTION_BUILD } from 'next/constants';
import log from 'utils/log/logger';
import requestLogger from './requestLogger';

let client: ApolloClient<NormalizedCacheObject>;
let clientWithCache: ApolloClient<NormalizedCacheObject>;

function wordpressApiUrl(): string {
  // eslint-disable-next-line max-len
  return `${process.env.WORDPRESS_URL || 'default'}${process.env.WORDPRESS_GQL_PATH || 'default'}`;
}

function removeLastTrailingSlash(url: string): string {
  if (typeof url !== 'string') return url;
  return url.replace(/\/$/, '');
}

export function getApolloClient(): ApolloClient<NormalizedCacheObject> {
  // at build time, use apollo client with cache;
  // when server runs, switch to client with no cache, in order to see updates with ISR
  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
    if (!clientWithCache) {
      clientWithCache = createApolloClient(true);
    }
    return clientWithCache;
  }
  if (!client) {
    client = createApolloClient(false);
  }
  return client;
}

const wordpressLink = new HttpLink({ uri: removeLastTrailingSlash(wordpressApiUrl()) });
const replatformLink = new HttpLink({ uri: process.env.REPLATFORM_GQL_URL });
const redVenturesLink = new HttpLink({ uri: process.env.RED_VENTURES_GQL_URL });

const otherLinks = ApolloLink.split(
  (operation) => operation.getContext().clientName === 'redVentures',
  redVenturesLink,
  wordpressLink,
);

export function createApolloClient(cache = false) {
  return new ApolloClient({
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
        fetchPolicy: cache ? 'cache-first' : 'no-cache',
      },
    },
    headers: {
      mode: 'no-cors',
    },
    link: ApolloLink.concat(
      requestLogger,
      ApolloLink.split(
        (operation) => operation.getContext().clientName === 'replatform', // Routes the query to the proper client
        replatformLink,
        otherLinks,
      ),
    ),
  });
}

export class ApolloQueryCache<T> {
  // used to keep track of individual requests start times
  // (ex: articles, each articles has different params, we'd like to cache each article individually, not contain all articles within one cache)
  private fetchTimes: { [key: string]: number };

  constructor(private name: string, private gqlQuery: DocumentNode, private ttlSeconds: number) {
    this.fetchTimes = {};
  }

  private isCacheExpired(key: string): boolean {
    const diff = Date.now() - this.fetchTimes[key];
    return diff > this.ttlSeconds * 1000;
  }

  public async fetch(variables?: { [key: string]: string | number | null }): Promise<T> {
    const apolloClient: ApolloClient<NormalizedCacheObject> = getApolloClient();
    let fetchPolicy: FetchPolicy = 'cache-first';
    let startTime: number;
    let response: ApolloQueryResult<T>;
    const key = JSON.stringify(variables);

    if (!this.fetchTimes[key] || this.isCacheExpired(key)) {
      fetchPolicy = 'network-only';
    }

    try {
      startTime = Date.now();
      response = await apolloClient.query({
        fetchPolicy,
        query: this.gqlQuery,
        variables: variables ?? {},
      });
    } catch (e) {
      throw new Error(`${this.name} Failed to query data: ${(e as Error).message}`);
    }

    if (response.errors) {
      throw new Error(`${this.name} ${JSON.stringify(response.errors)}`);
    }

    if (fetchPolicy === 'network-only') {
      this.fetchTimes[key] = startTime;
    }

    // the other logger will log all graphql requests that are not cached
    if (fetchPolicy === 'cache-first') {
      log.message('cache-hit', this.name, {}, Date.now() - startTime);
    }
    return response.data;
  }
}
