import DevLogger from './devLogger';
import Logger from './logger';
import ProdLogger from './prodLogger';

/**
 * Server-side logger
 * Do not use this from the frontend - use `logToBackend` instead
 */
const logger: Logger =
  process.env.NODE_ENV === 'development' && !process.env.FORCE_PROD_LOGGER ? new DevLogger() : new ProdLogger();

export default logger;
