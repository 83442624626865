import { ApolloLink } from '@apollo/client';
import log from 'utils/log/logger';

const loggerLink = new ApolloLink((operation, forward) => {
  const start = Date.now();
  return forward(operation).map((result) => {
    log.message('graphql', operation.operationName, operation.variables, Date.now() - start);
    return result;
  });
});

export default loggerLink;
